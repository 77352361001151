import Compact from "@uiw/react-color-compact";
import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PICTURE_URL } from "../../helpers/api.routes";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { companySlice } from "../../store/slices/company.slice";
import { AdminCompanyDetailsService } from "./service";
export const AdminCompanyDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [state, setState] = useState({ color: "#fff" } as any);

  const [image, setImage] = useState<File>();
  const [imageInvert, setImageInvert] = useState<File>();
  const [favicon, setFavicon] = useState<File>();
  const [aboImage, setAboImage] = useState<File>();
  const dispatch = useDispatch();

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const fileHandler = (e: any) => {
    if (e.target.id === "documentFile") {
      setFavicon(e.target.files[0]);
    } else if (e.target.id === "imageFileInvert") {
      setImageInvert(e.target.files[0]);
    } else if (e.target.id === "aboImage") {
      setAboImage(e.target.files[0]);
    } else {
      setImage(e.target.files[0]);
    }
  };

  const submitHandler = (event: any) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append(
      "body",
      JSON.stringify({
        name: state?.name,
        street: state?.street,
        postCode: state?.postCode,
        websiteTitle: state?.websiteTitle,
        metaDescription: state?.metaDescription,
        metaKeyword: state?.metaKeyword,
        city: state?.city,
        phone: state?.phone,
        fax: state?.fax,
        email: state?.email,
        facebook: state?.facebook,
        // twitter: state?.twitter,
        linkedin: state?.linkedin,
        color: state?.color,
        codeEmbed: state?.codeEmbed,
        map: state?.map,
        youtube: state?.youtube,
        clientDomain: state?.clientDomain,
      })
    );

    formData.append("logo", image as File);
    formData.append("logoInvert", imageInvert as File);
    formData.append("aboImage", aboImage as File);
    formData.append("favicon", favicon as File);

    AdminCompanyDetailsService.create(formData)
      .then((response) => {
        dispatch(companySlice.actions.setCompany(response?.data));

        // window.location.reload();
        SuccessToast(t(`company_details.successfully_updated`));
      })
      .catch((error) => ErrorToast(error));
  };

  // const submitHandler = (event: any) => {

  // multipartFormData(
  //   image,
  //   `${COMPANY_DETAILS_URL}`,
  //   JSON.stringify({
  //     name: state?.name,
  //     street: state?.street,
  //     postCode: state?.postCode,
  //     city: state?.city,
  //     phone: state?.phone,
  //     fax: state?.fax,
  //     email: state?.email,
  //     facebook: state?.name,
  //     twitter: state?.twitter,
  //     linkedin: state?.linkedin,
  //     youtube: state?.youtube,
  //   }),
  //   "post",
  //   "logo"
  // )
  //   .then((response) => {
  //     SuccessToast(t(`company_details.successfully_updated`));
  //   })
  //   .catch((error) => ErrorToast(error));

  //   AdminCompanyDetailsService.create(state)
  //     .then((response) => {
  //       SuccessToast(t(`company_details.successfully_updated`));
  //     })
  //     .catch((error) => ErrorToast(error));
  // };
  useEffect(() => {
    AdminCompanyDetailsService.getAll()
      .then((response) => {
        const { data } = response;
        setState(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row className="my-3">
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.name}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="email" className="required-field">
              {t("global.email")}
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.email}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="email" className="required-field">
              {t("global.client_domain")}
            </label>
            <input
              type="url"
              name="clientDomain"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.clientDomain}
            />
          </Col>
        </Row>
        <Row className="my-1">
          <Col md={3}>
            <label htmlFor="websiteTitle" className="required-field">
              {t("global.website_title")}
            </label>
            <input
              type="text"
              name="websiteTitle"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.websiteTitle}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="phone" className="required-field">
              {t("global.meta_description")}
            </label>
            <input
              type="text"
              name="metaDescription"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.metaDescription}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="phone" className="required-field">
              {t("global.phone")}
            </label>
            <input
              type="tel"
              name="phone"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.phone}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="phone" className="required-field">
              {t("global.meta_keyword")}
            </label>
            <input
              type="text"
              name="metaKeyword"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.metaKeyword}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={3}>
            <label htmlFor="fax" className="required-field">
              {t("global.fax")}
            </label>
            <input
              type="text"
              name="fax"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.fax}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="city" className="required-field">
              {t("global.city")}
            </label>
            <input
              type="text"
              name="city"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.city}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="street" className="required-field">
              {t("global.street")}
            </label>
            <input
              type="text"
              name="street"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.street}
            />
          </Col>
          <Col md={3}>
            <label htmlFor="postCode" className="required-field">
              {t("global.postCode")}
            </label>
            <input
              type="text"
              name="postCode"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.postCode}
            />
          </Col>
        </Row>
        <Row className="my-1">
          <Col md={4}>
            <label htmlFor="facebook" className="required-field">
              {t("global.facebook")}
            </label>
            <input
              type="text"
              name="facebook"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.facebook}
            />
          </Col>
          {/* <Col md={3}>
            <label htmlFor="twitter" className="required-field">
              {t("global.twitter")}
            </label>
            <input
              type="text"
              name="twitter"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.twitter}
            />
          </Col> */}
          <Col md={4}>
            <label htmlFor="linkedin" className="required-field">
              {t("global.linkedin")}
            </label>
            <input
              type="text"
              name="linkedin"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.linkedin}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="youtube" className="required-field">
              {t("global.youtube")}
            </label>
            <input
              type="text"
              name="youtube"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.youtube}
            />
          </Col>
          <Col md={9} className="my-2">
            <div>
              <label htmlFor={`embedded_code`} className="form-label">
                {t("table_headers.embedded_code")}
              </label>
              <textarea
                className="form-control"
                id={`embedded_code`}
                rows={6}
                name="codeEmbed"
                value={state?.codeEmbed}
                onChange={(e) => changeHandler(e as any)}
              ></textarea>
            </div>
          </Col>
          <Col md={3} className="my-3">
            <label htmlFor="youtube" className="required-field">
              {t("global.colorPicker")}
            </label>
            <Compact
              style={{ marginLeft: 20 }}
              color={state.color}
              onChange={(color) => {
                setState((prev: any) => ({ ...prev, color: color.hex }));
              }}
            />
          </Col>
          <Col md={12} className="my-2">
            <div>
              <label htmlFor={`map`} className="form-label">
                {t("table_headers.map")}
              </label>
              <textarea
                  className="form-control"
                  id={`map`}
                  rows={6}
                  name="map"
                  value={state?.map}
                  onChange={(e) => changeHandler(e as any)}
              ></textarea>
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <div className="">
            <label htmlFor="imageFile"> {t("global.logo")}</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="imageFile"
            />
          </div>
          {state?.logo && (
            <div className="col-md-6 py-3">
              <img
                className="rounded"
                alt="200x200"
                width={200}
                src={PICTURE_URL + state?.logo.path}
                data-holder-rendered="true"
              />
            </div>
          )}
        </Row>
        <Row className="my-3">
          <div className="">
            <label htmlFor="imageFileInvert"> {t("global.logo")} invert</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="imageFileInvert"
            />
          </div>
          {state?.logoInvert && (
            <div className="col-md-6 py-3">
              <img
                className="rounded"
                alt="200x200"
                width={200}
                src={PICTURE_URL + state?.logoInvert.path}
                data-holder-rendered="true"
              />
            </div>
          )}
        </Row>
        <Row className="my-1">
          <div className="">
            <label htmlFor="imageFile"> {t("global.favicon")}</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="documentFile"
            />
          </div>
          {state?.favicon && (
            <div className="col-md-6 py-3">
              <img
                className="rounded"
                alt="40x40"
                width={40}
                src={PICTURE_URL + state?.favicon.path}
                data-holder-rendered="true"
              />
            </div>
          )}
        </Row>
        <Row className="my-1">
          <div className="">
            <label htmlFor="imageFile"> {t("global.aboImage")}</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="aboImage"
            />
          </div>
          {state?.aboImage && (
            <div className="col-md-6 py-3">
              <img
                className="rounded"
                alt="200"
                width={200}
                src={PICTURE_URL + state?.aboImage.path}
                data-holder-rendered="true"
              />
            </div>
          )}
        </Row>
        <Row className="mt-3">
          <div className="d-flex justify-content-end text-align-center">
            <Form
              onSubmit={(e) => {
                submitHandler(e);
              }}
            >
              <button className="btn btn-primary" type="submit">
                {t("category.save")}
              </button>
            </Form>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};
