import { createSlice } from "@reduxjs/toolkit";

export interface IEnum {
  subscriberCategory: string[];
  menuPosition: string[];
  website: string[];
  userRoles: string[];
  siteSettings?: string[];
  userStatus?: string[];
  greeting: string[];
  genders: string[];
  firmType: string[];
  language: string[];
  months: string[];
  years: string[];
}

const initialState: IEnum = {
  subscriberCategory: [],
  firmType: [],
  menuPosition: [],
  website: [],
  userRoles: [],
  greeting: [],
  genders: [],
  language: [],
  months: [],
  years: []
};

export const enumSlice = createSlice({
  name: "enum",
  initialState,
  reducers: {
    setEnums: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
